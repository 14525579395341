import React from "react"
import ScrollableAnchor from 'react-scrollable-anchor'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Words = () => {

        return (
            <Layout>
                <SEO title="Glossary" />
                <h1>Woordenlijst</h1>
                    <div id="Devops">
                        <h2>DevOps</h2>
                        <p>Uitleg over DevOps</p>
                    </div>
                    <div id="Deployen">
                    <h2>Deployen</h2>
                    <p>Uitleg over Deployen</p>
                    </div>
                    <div>
                    <h2>Pipeline</h2>
                    <p>Uitleg over Pipeline</p>
                    </div>
                    <div id="CI">
                    <h2>Continuous Integration --- CI</h2>
                    <p>Uitleg over Continuous Integration --- CI</p>
                    </div>
                    <div>
                    <h2>Continuous Deployment/Delivery --- CD</h2>
                    <p>Uitleg over Continuous Deployment/Delivery --- CD</p>
                    </div>
                    <div>
                    <h2>Flowchart</h2>
                    <p>Uitleg over Flowchart</p>
                    </div>
            </Layout>
        )
    }


export default Words

// # DevOps
// # Deployen
// # Pipeline
// # Continuous Integration --- CI
// # Continuous Deployment --- CD
// # Library 
// # Flowchart
// # Modules
// # Low en high fidelity 